<template>
  <div class="contact">
    <div class="header">
      <div class="callout">Have Questions <br>or<br> Need More Info?</div>
      <v-img src="@/assets/contact.jpg" alt="contact img"/>
    </div>
    <div class="contact-cards">
      <div class="div col-xs-12 col-md-6 contact-card">
        <div class="icon"><v-icon size="">fas fa-envelope-square</v-icon></div>
        <div class="info-section">
          <div class="header">Email Us Anytime</div>
          <div class="details">info@emsbio.com</div>
        </div>
      </div>
      <div class="div col-xs-12 col-md-6 contact-card">
        <div class="icon"><v-icon>fas fa-phone-square</v-icon></div>
        <div class="info-section">
          <div class="header">Give Us a Call</div>
          <div class="details d-flex flex-column">
            <span>801-948-0804</span>
            <span>Available 8:00 am - 5:00 pm MST</span>
          </div>
        </div>
      </div>
<!--      <div class="div col-xs-12 col-md-6 contact-card">-->
<!--        <div class="icon"><v-icon>fas fa-calendar</v-icon></div>-->
<!--        <div class="info-section">-->
<!--          <div class="header">Schedule an Appointment</div>-->
<!--          <div class="details">We know you’re busy so schedule a time to talk that works best for you!</div>-->
<!--        </div>-->
<!--      </div>-->
    </div>
    <div class="callout">
      <span class="text">We are here to support you.  Please let us know how we can help!</span>
    </div>

  </div>
</template>

<script>
export default {
  name: 'Contact',
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: 'Contact Us',
    meta: [
      { name: 'description', content: 'Contact info' },
    ],
    // all titles will be injected into this template
    titleTemplate: '%s | Biolab Fluid GF Q4206'
  },
  props: {},
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.contact {
  .callout {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
    .text {
      font-size: 30px;
      margin: 1rem;
    }
    @media only screen and (max-width: 600px) {
      .text {
        font-size: 20px;
      }
    }
  }
  &-cards {
    display: flex;
    flex-wrap: wrap;
    .icon {
      i {
        font-size: 100px;
        color: #00aeef;
        //color: #fb7c34;
      }
    }
    .info-section {
      margin-left: 1rem;
      .header {
        font-size: 30px;
        color: #507DBC;
      }
    }
  }
  &-card {
    display: flex;
  }
  .header {
    display: flex;
    align-items: center;
    .callout {
      position: absolute;
      z-index: 1;
      margin-top: -5%;
      right: 15%;
      font-size: 50px;
      max-width: 400px;
      text-align: center;
      color: #507DBC;
    }
    @media only screen and (max-width: 900px) {
      .callout {
        font-size: 34px;
      }
    }
    @media only screen and (max-width: 600px) {
      .callout {
        font-size: 20px;
      }
    }
  }
}
</style>
